'use client';
import _get from 'lodash/get';
import { useContext } from 'react';

import { FeatureFlagContext } from './FeatureContext';

import { useLocale } from 'next-intl';
import { LocaleBasedOptions } from '@utils/api/server/wr/options';

export const useFeatureFlagData = () => {
  const features = useContext(FeatureFlagContext);
  const locale = useLocale();
  return {
    features,
    options: features?.lang_based.find((o) => o.lang === locale) as LocaleBasedOptions,
    getOption: (option: string) =>
      (locale ? _get(features?.lang_based.find((o) => o.lang === locale), option) : _get(features, option)) ??
      _get(features, option),
  };
};
