'use client';
import { ReactNode } from 'react';

import { useFeatureFlagData } from '@context/FeatureContextClient';
import { isFeatureValueActive } from '@utils/api/server/wr/cookie';

const knownFlags = [
  'article.socialLoginFirstScreen',
  'article.newsAbleToBeBlocked',
  'auth.registerVersion',
  'auth.rememberMe',
  'premium.enabled',
  'premium.buy_enabled',
  'main_page.main_page_version',
] as const;

type KnownFlag = (typeof knownFlags)[number];

const getClientSideCookie = (name: string): string | undefined => {
  if (typeof document === 'undefined') {
    return undefined;
  }
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`))
    ?.split('=')[1];

  return cookieValue;
};

export const useFeatureIsActive = (flagName: KnownFlag, value?: any) => {
  const { getOption } = useFeatureFlagData();

  return isFeatureValueActive(getClientSideCookie(flagName), getOption(`flags.${flagName}`), value);
};

export const FeatureIsActive = ({
  name,
  value,
  invert,
  children,
}: {
  name: KnownFlag;
  value?: any;
  invert?: boolean;
  children: ReactNode;
}) => {
  const isFeatureActive = useFeatureIsActive(name, value);

  return (invert && !isFeatureActive) || (!invert && isFeatureActive) ? <>{children}</> : null;
};
